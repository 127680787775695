import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from './enums';
import { NamespaceConsumer, NamespaceShape, withNamespace } from '@ux/namespace-component';
import { IntlProvider } from 'react-intl';
import Icon from '@ux/icon';
import WaffleMenu from './waffle';

/**
 * Render Big Three product dropdown.
 *
 * @Constructor
 * @class BigThree
 * @api public
 */
class AppName extends NamespaceConsumer {
  /**
   * Add eventListeners for breakpoints.
   *
   * @api private
   */
  componentDidMount() {
    this.breakpoint(...breakpoints);
  }

  /**
   * Remove the eventListeners of breakpoints.
   *
   * @api private
   */
  componentWillUnmount() {
    this.breakup();
  }

  render() {
    const { market, messages, showWaffleMenu, waffleLeft, name } = this.props;
    let content;

    const productName = <span className={ this.namespace('product-name') }>{ name }</span>;

    if (showWaffleMenu) {
      const control = (
        <Fragment>
          <Icon name='apps'/>
          {!waffleLeft && productName}
        </Fragment>
      );
      content = <WaffleMenu { ...this.props } control={ control } />;
    } else {
      content = productName;
    }

    return (
      <IntlProvider locale={ market } messages={ messages }>
        { content }
      </IntlProvider>
    );
  }
}

AppName.propTypes = {
  ...NamespaceShape,
  urls: PropTypes.object.isRequired,
  privateLabelId: PropTypes.number,
  app: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool,
  market: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  showWaffleMenu: PropTypes.bool
};

export default withNamespace(AppName);
