import React from 'react';
import PropTypes from 'prop-types';
import { NamespaceConsumer, NamespaceShape, withNamespace } from '@ux/namespace-component';
import { Navigation as NamespacedNavigation } from './nav-bottom';

class NavigationSet extends NamespaceConsumer {
  constructor(props) {
    super(...arguments);

    this.state = {
      ...this.state,
      emptyNav: props.navigation.every(navItem => !navItem.caption),
      emptyNavRight: !props.navigationRight || props.navigationRight.every(navItem => !navItem.caption)
    };
  }

  /**
   * Add eventListeners for breakpoints.
   *
   * @private
   */
  componentDidMount() {
    this.breakpoint('mobile', 'phablet', 'tablet', 'desktop', 'large');
  }

  /**
   * Remove the eventListeners of breakpoints.
   *
   * @private
   */
  componentWillUnmount() {
    this.breakup();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        emptyNav: nextProps.navigation.every(navItem => !navItem.caption),
        emptyNavRight: !nextProps.navigationRight || nextProps.navigationRight.every(navItem => !navItem.caption)
      });
    }
  }

  render() {
    const props = this.props;
    const state = this.state;
    const { navigation, navigationRight, navigationPosition, renderMobile } = props;
    const isNotDesktop = (['mobile', 'phablet', 'tablet'].indexOf(this.state.breakpoint) !== -1);
    //
    // Remark: Do NOT render the navigation server side it will cause mobile clients
    // to briefly show/flash a large or desktop rendered navigation view.
    //
    if (typeof window === 'undefined' || (state.emptyNav && state.emptyNavRight)) {
      return null;
    }

    // if renderMobile is explicitly set to false, do not render mobile
    if (isNotDesktop && !state.emptyNav && renderMobile !== false) {
      return (
        <div className={ this.namespace(`${navigationPosition}-nav-set`) }>
          <NamespacedNavigation { ...props }
            items={ navigation }
            renderMobile={ true }
            renderEmptyTitle={ navigationPosition !== 'bot' } />
        </div>
      );
    }

    return (
      <div className={ this.namespace(`${navigationPosition}-nav-set`) }>
        <NamespacedNavigation items={ navigation } />
        <NamespacedNavigation items={ navigationRight } side='right' />
      </div>
    );
  }
}

/**
 * Default properties for navigation bar.
 *
 * @type {Object}
 * @api public
 */
NavigationSet.defaultProps = {
  navigation: [],
  navigationPosition: 'bot'
};

/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */
NavigationSet.propTypes = {
  ...NamespaceShape,
  navigation: PropTypes.array.isRequired,
  navigationRight: PropTypes.array,
  renderMobile: PropTypes.bool,
  navigationPosition: PropTypes.oneOf(['bot', 'top'])
};

export default withNamespace(NavigationSet);
