import React from 'react';
import { NamespaceProvider, NamespaceContext } from '@ux/namespace-component';
import AccountDelegation from '@ux/account-delegation';
import { events, Customer, Employee } from '@ux/header-util';
import NavigationBottom from './nav-bottom';
import NavigationTop from './nav-top';
import ApplicationFooter from '@ux/application-footer';
import AbstractHeader from '@ux/abstract-header';
import HeaderComponent from './header';
import assign from 'object-assign';
import PropTypes from 'prop-types';

/**
 * Return a namespace if the environment variable is set, 'polyfilled' by WebPack.
 *
 * @returns {Object} Configured namespace.
 * @api private
 */
function namespace() {
  return {
    namespace: process.env.NAMESPACE // eslint-disable-line no-process-env
  };
}

/**
 * Render and expose the Header as a functional Component.
 *
 * @param {Object} props Properties
 * @returns {ReactElement} Namespaced Header.
 * @api public
 */
class Header extends AbstractHeader {
  constructor() {
    super(...arguments);
    this.state = {
      ...this.state,
      helpVariant: false
    };
    this.updateNavigation = NavigationBottom.update.bind(this);
    this.updateTopNavigation = NavigationTop.update.bind(this);
    this.updateHelpUrl = NavigationTop.updateHelpUrl.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.pipeExpVariantToState({
      expId: 'uxp_appheader_helpbyGDG',
      attributes: {
        market: this.props.market
      },
      stateFieldName: 'helpVariant'
    });
  }

  /**
   * Returns a valid Customer instance using the props associated
   * with this instance. If `internalMode` is `true` returns an `Employee`.
   *
   * @returns {User} Customer or Employee instance from @ux/header-util.
   */
  createCustomer() {
    const props = this.props;

    // Override customer if internal mode
    if (props.preset === 'internal-header') {
      return new Employee(props);
    }

    return new Customer(props);
  }

  /**
   * Prefer state over props when assigning to children
   *
   * @returns {Object} Child props
   * @api private
   */
  getChildProps() {
    const { helpUrl } = this.state;
    const childProps = assign({}, this.props, this.state);
    if (helpUrl) childProps.urls.help.href = helpUrl;
    return childProps;
  }

  render() {
    const childProps = this.getChildProps();

    return (
      <NamespaceProvider { ...namespace() }>
        <NamespaceContext.Consumer>
          { context =>
            <div className={ context.namespace(this.classNames({
              loaded: this.isBrowser
            })) }>
              { this.renderBanner(childProps) }
              { childProps.preset === 'internal-header' ? null : <AccountDelegation
                market={ childProps.market }
                messages={ childProps.messages }
                action={ childProps.urls.sso.exitDelegation.href }
                restoreCookie={ childProps.urls.sso.restoreCookie.href }
                customer={ this.customer } /> }
              <HeaderComponent { ...childProps } />
            </div>
          }
        </NamespaceContext.Consumer>
      </NamespaceProvider>
    );
  }
}

Header.propTypes = {
  market: PropTypes.string.isRequired,
  urls: PropTypes.object.isRequired,
  resellerUrls: PropTypes.object,
  privateLabelType: PropTypes.number,
  privateLabelId: PropTypes.number,
  preset: PropTypes.string,
  traffic: PropTypes.string
};

Header.defaultProps = {
  preset: 'application-header'
};

/**
 * Render and expose the Footer as a functional Component.
 *
 * @param {Object} props Properties
 * @returns {ReactElement} Namespaced Footer.
 * @api public
 */
class Footer extends React.Component {
  componentDidMount() {
    events.emit('mount', 'footer', this);
    events.emit('mount:footer', this);
  }

  render() {
    return (
      <NamespaceProvider { ...namespace() }>
        <ApplicationFooter { ...this.props } />
      </NamespaceProvider>
    );
  }
}

export {
  Header,
  Footer
};
