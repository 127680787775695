import MobileNav from '@ux/mobile-nav';
import { TrayMenu } from '@ux/tray-menu';
import Link from '@ux/link';
import { NamespaceConsumer, NamespaceShape, withNamespace } from '@ux/namespace-component';
import { IntlProvider } from 'react-intl';
import { URL } from '@ux/util';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@ux/button';
import NamespacedNavSet from './nav-set';

const isDataAttr = /^data-[a-zA-Z0-9-]+$/;
const isRelativeURL = /^\/(?:[^/]|$)/;
const absoluteURLPrefix = /^https?:\/\/[^/]*/;

class Navigation extends NamespaceConsumer {
  constructor(props) {
    super(...arguments);

    this.currentHref = this.getCurrentHref();
    this.renderItems = this.renderItems.bind(this);
    this.setDataAttrs = this.setDataAttrs.bind(this);
    this.closeNavTrays = this.closeNavTrays.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.trayMenus = Object.create(null);

    props.items.map(this.setDataAttrs);
  }

  /**
   * When component gets new props, mutate `items` property using Array.map
   * @param  {object} nextProps The set of updated properties
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.items) {
      this.setState({
        items: nextProps.items.map(this.setDataAttrs)
      });
    }
  }

  /**
   * Close all navigation menus
   *
   * @memberof Navigation
   * @private
   */
  closeNavTrays() {
    for (const menu in this.trayMenus) { // eslint-disable-line guard-for-in
      this.trayMenus[menu].close();
    }
  }

  /**
   * Function to call when clicking an item.
   *
   * @param {Object} item The item containing a custom onClick function
   * @param {Event} event The click event
   * @memberof Navigation
   * @private
   */
  onItemClick(item, event) {
    if (item.onClick) {
      item.onClick(event);
    }
    this.closeNavTrays();
  }


  /**
   * Sets a dataAttrs field containing all the `data-` attributes on a given item.
   * @param  {object} item A navigation item
   * @returns {Object} The updated navigation item with a `dataAttrs` field
   * @private
   */
  setDataAttrs(item) {
    if (!item) return;

    item.dataAttrs = Object.keys(item).reduce(function (acc, k) {
      if (isDataAttr.test(k)) { acc[k] = item[k]; }
      return acc;
    }, {});

    if (item.children) {
      item.children.forEach(this.setDataAttrs);
    }

    return item;
  }

  /**
   * Get the current page href for comparison of active item
   *
   * @returns {String} current href
   * @private
   */
  getCurrentHref() {
    //
    // Logic originally from
    // https://github.secureserver.net/UX/product-nav/blob/119ebd4ffc55e41a4bfebe1c973f55de0c3e35bc/lib/product-nav.js#L37
    //
    let { currentHref } = this.props;
    if (this.isBrowser) {
      currentHref = document.location.href;
    }

    if (currentHref) {
      const currentUrl = new URL(currentHref, true);
      const qsCurrentHref = currentUrl.query.currentPage;
      if (qsCurrentHref) {
        currentHref = qsCurrentHref;
      } else {
        // Remove query string for comparisons
        currentUrl.set('query', {});
        currentHref = currentUrl.href;
      }
    }

    return currentHref;
  }

  /**
   * Determine if an item is active, or if any of it's children are active.
   *
   * @param {Object} item nav item
   * @param {Boolean} [recurse=true] flag to enable recursion of children
   * @returns {Boolean} whether item is active
   * @private
   */
  isActive(item, recurse) {
    if (typeof (item.active) === 'boolean') return item.active;

    if (item.href && item.href === this.currentHref) return true;

    // Handle relative URLs
    if (this.currentHref && isRelativeURL.test(item.href) && item.href === this.currentHref.replace(absoluteURLPrefix, '')) {
      return true;
    }

    if (recurse !== false && item.children) {
      return item.children.some(child => this.isActive(child));
    }

    return false;
  }

  /**
   * Renders mobile navigation
   * @param  {[Object]} items The navigation items to render
   * @returns {ReactElement} an instance of MobileNav
   */
  renderMobile(items) {
    const props = this.props;
    let activeHeading;

    for (let i = 0; i < items.length; i++) {
      if (this.isActive(items[i])) {
        activeHeading = items[i];
        break;
      }
    }

    return <MobileNav { ...props }
      nav={ items }
      menuTitle={ props.appName }
      currentHref={ this.currentHref }
      activeHeading={ activeHeading }
      showHomeLink={ true }
      literalCaptions={ true }
      useActiveHeadingAsMenuTitle={ true }
      showTitle={ !props.renderEmptyTitle } />;
  }

  /**
   * Render a uxicon.
   *
   * @param {String} classname The classname corresponding to the uxicon
   * @returns {Element} Span containing a uxicon
   * @private
   */
  renderNavIcon(classname) {
    return <span className={ this.namespace(classname, 'icon', 'uxicon') }></span>;
  }

  /**
   * Render a column of nav items. Used inside of a multi-column tray.
   * @param  {Object} item Navigation item whose children to render in a column
   * @param  {String} key The key for the heading list elements
   * @returns {Element[]} Array of list elements containing a heading and links
   */
  renderColumn(item, key) {
    const { renderMobile } = this.props;
    // discard if it's mobile and mobileOnly
    if (item.mobileOnly && !renderMobile) {
      return null;
    }
    const columnItems = [];

    // push the heading for the column if there is one
    if (item.caption) {
      columnItems.push(
        <li
          key={ key }
          className={ this.namespace('heading', 'title', 'small') }
          { ...item.dataAttrs }>
          { item.caption }
          { item.icon && this.renderNavIcon(item.icon) }
        </li>
      );
    }
    // then push the links
    for (var i = 0; i < item.children.length; i++) {
      const childItem = item.children[i];
      if (childItem.mobileOnly && !renderMobile) {
        continue;
      }

      const classNames = this.classNames(item.className,
        this.namespace(this.classNames({
          active: this.isActive(childItem, false)
        })));
      const props = {
        'className': classNames,
        'target': childItem.target,
        'id': childItem.id,
        'href': childItem.href || '#',
        'data-eid': childItem.eid,
        'onClick': (e => { this.onItemClick(childItem, e); }),
        'onClicked': this.closeNavTrays,
        ...childItem.dataAttrs
      };
      columnItems.push(
        <li
          key={ (childItem.caption || childItem.id) + i }
          className={ childItem.className }>
          <Link { ...props }>
            { childItem.caption }
            { childItem.icon && this.renderNavIcon(childItem.icon) }
          </Link>
        </li>
      );
    }
    return columnItems;
  }

  /**
   * Render a single nav item. Used inside of a single column tray.
   * @param  {Object} item Navigation item to render
   * @param  {String} key The key for each list element
   * @returns {Element} List element containing a @ux/link
   */
  renderSingleItem(item, key) {
    if (item.mobileOnly && !this.props.renderMobile) {
      return null;
    }
    const classNames = this.classNames(item.className,
      this.namespace(this.classNames({
        active: this.isActive(item, false)
      })));
    const props = {
      'className': classNames,
      'target': item.target,
      'id': item.id,
      'href': item.href || '#',
      'data-eid': item.eid,
      'onClick': (e => { this.onItemClick(item, e); }),
      'onClicked': this.closeNavTrays,
      ...item.dataAttrs
    };
    return <li key={ key } className={ props.className }>
      <Link { ...props }>
        { item.caption }
        { item.icon && this.renderNavIcon(item.icon) }
      </Link>
    </li>;
  }

  /**
   * Create a list with all nested data structures
   * rendered appropriately.
   *
   * @param {Object} navItem the parent nav item of a collection of columns
   * @param {Boolean} multi True if the nav structure is multi-column
   * @returns {ReactElement} Columns
   * @private
   */
  renderColumns(navItem, multi) {
    if (multi) {
      return navItem.children.map((child, k) => {
        return <ul
          key={ k }
          className={ this.namespace('list-unstyled', 'nav-column') }
          { ...child.dataAttrs }>
          { this.renderColumn(child, k) }
        </ul>;
      });
    }

    return <ul
      className={ this.namespace('list-unstyled', 'nav-column') }
      { ...navItem.dataAttrs }>
      { navItem.children.map((child, k) => {
        return this.renderSingleItem(child, k);
      })}
    </ul>;
  }

  /**
   * Determines if the root navigations structure is multi- or simgle columned
   *
   * @param {Object} navItem the root node of the nav structure
   * @returns {Boolean} true if multi column
   * @private
   */
  isMultiColumn(navItem) {
    return navItem.children &&
      navItem.children.every(child => Array.isArray(child.children));
  }

  /**
   * Render items that appear on the right side of the navigation
   * @param  {Object} item Object containing details of the navigation item
   * @param  {ReactElement} icon Span containing a uxicon
   * @param  {Number} i Index value
   * @returns {ReactElement} Either a button-link or a string of text
   * @private
   */
  renderNavRightItem(item, icon, i) {
    if (item.href) {
      const design = item.design || 'default';
      const size = 'small';

      return (
        <li key={ i } className={ this.namespace('nav-item') }>
          <Button
            design={ design }
            size={ size }
            id={ item.id }
            href={ item.href }
            target={ item.target }
            onClick={ item.onClick }
            data-eid={ item.eid }
            { ...item.dataAttrs }
          >
            { icon }
            { item.caption }
          </Button>
        </li>
      );
    }
    return (
      <li key={ i } className={ this.namespace('nav-item') }>{ item.caption }</li>
    );
  }

  /**
   * Render the navigation items
   *
   * @param {Object} item nav item
   * @param {Number} i index value
   * @returns {ReactElement} ReactElement
   * @private
   */
  renderItems(item, i) {
    // discard if it's mobile and mobileOnly
    if (item.mobileOnly && !this.props.renderMobile) {
      return null;
    }
    const isActive = this.isActive(item);
    const classNames = this.namespace(this.classNames('nav-item', {
      active: isActive
    }));

    let icon;
    if (item.icon) {
      icon = this.renderNavIcon(item.icon);
    }

    //
    // Render a tray-menu if the current item has children regardless of side
    //
    if (item.children && item.children.length) {
      const multiColumn = this.isMultiColumn(item);
      const columns = this.renderColumns(item, multiColumn);
      const trayTitle = <div className={ this.namespace('tray-title') }>
        {item.caption}
        {icon}
      </div>;

      return <li className={ classNames } key={ i }>
        <TrayMenu
          ref={ r => { this.trayMenus[i] = r; } }
          className={
            this.namespace(this.classNames({ 'multi-col': multiColumn }))
          }
          showOpenCaret={ true }
          name={ trayTitle }
          data-eid={ item.eid }
          fullwidth={ false }
          showClose={ false }
          { ...item.dataAttrs }>

          { trayTitle }
          <div className={ this.namespace('flex-columns') }>
            { columns }
          </div>
        </TrayMenu>
      </li>;
    }

    // if no children, handle rendering for right side
    if (this.props.side === 'right') {
      return this.renderNavRightItem(item, icon, i);
    }

    // handle render for left side
    return (
      <li key={ i } className={ classNames }>
        <a
          className={ this.namespace('nav-link', 'font-base') }
          id={ item.id }
          href={ item.href }
          target={ item.target }
          onClick={ item.onClick }
          data-eid={ item.eid }
          { ...item.dataAttrs }
        >
          { item.caption }
          { icon }
        </a>
      </li>
    );
  }


  /**
   * Render the navigation component
   *
   * @returns {ReactElement} ReactElement
   */
  render() {
    const props = this.props;
    const items = props.items;

    if (!items.length) {
      return null;
    }

    if (props.renderMobile) {
      return this.renderMobile(items);
    }

    return (
      <ul className={ this.namespace('nav', `nav-${props.side}`) }>
        { items.map(this.renderItems) }
      </ul>
    );
  }
}

Navigation.defaultProps = {
  side: 'left',
  renderEmptyTitle: false,
  items: []
};

/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */
Navigation.propTypes = {
  ...NamespaceShape,
  items: PropTypes.array,
  side: PropTypes.string,
  renderMobile: PropTypes.bool,
  renderEmptyTitle: PropTypes.bool
};
const NamespacedNavigation = withNamespace(Navigation);

class NavigationBottom extends NamespaceConsumer {
  render() {
    const props = this.props;

    return (
      <IntlProvider locale={ props.market } messages={ props.messages }>
        <nav className={ this.namespace('nav-bottom', 'clearfix') }>
          <div className={ this.namespace('container') }>
            <NamespacedNavSet { ...props } />
          </div>
        </nav>
      </IntlProvider>
    );
  }
}

/**
 * Helper to update navigations (regular or right sided). Clones object or arrays
 * to ensure setState is done against a new object in memory.
 *
 * @param {Array|Object} nav Updated navigation, can be navigation array or object.
 * @param {Boolean} right Optionally update right side navigation, defaults to regular.
 * @param {Function} done Optional completion callback.
 * @returns {Self} fluent interface.
 * @api public
 */
NavigationBottom.update = function update(nav, right = false, done = () => {}) {
  if (typeof right === 'function') {
    done = right;
    right = false;
  }

  const navs = ['navigation', 'navigationRight'];
  const prop = navs[+right];

  if (Array.isArray(nav)) {
    return this.setState({
      [prop]: nav.slice()
    }, done);
  }

  return this.setState(navs.reduce((memo, key) => {
    if (Array.isArray(nav[key])) {
      memo[key] = nav[key].slice();
    }

    return memo;
  }, {}), done);
};


/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */

const rightNavPropTypes = PropTypes.shape({
  'blacklist': PropTypes.object,
  'whitelist': PropTypes.object,
  'caption': PropTypes.string,
  'data-mix': PropTypes.string,
  'disposition': PropTypes.string,
  'eid': PropTypes.string,
  'href': PropTypes.string,
  'children': PropTypes.array,
  'design': PropTypes.oneOf(['purchase', 'primary', 'default'])
});

NavigationBottom.propTypes = {
  ...NamespaceShape,
  market: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  navigation: PropTypes.array.isRequired,
  navigationRight: PropTypes.arrayOf(rightNavPropTypes)
};

export {
  NamespacedNavigation as Navigation
};
export default withNamespace(NavigationBottom);
