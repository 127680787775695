/* eslint-disable react/jsx-pascal-case */
import { golf } from './enums';
import { NamespaceConsumer, NamespaceShape, withNamespace } from '@ux/namespace-component';
import { IntlProvider, FormattedMessage } from 'react-intl';
import OrderConfirmation from '@ux/order-confirmation';
import Notifications from '@ux/notifications';
import Icon from '@ux/icon';
import InappHelp from '@ux/inapp-help';
import HelpLoader from '@ua/help-loader';
import Cart from '@px/header-cart';
import Logo from '@ux/logo';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AppName from './app-name';
import { User } from './account-tray';
import NavigationSet from './nav-set';

/**
 * Render Version.
 *
 * @class Version
 * @api public
 */
class Version extends NamespaceConsumer {
  render() {
    return (
      <Fragment>
        { this.props.value }
      </Fragment>
    );
  }
}

Version.propTypes = {
  ...NamespaceShape,
  value: PropTypes.string
};

const NamespacedVersion = withNamespace(Version);

/**
 * Render Application Header Footer.
 *
 * @class NavigationTop
 * @api public
 */
class NavigationTop extends NamespaceConsumer {
  constructor() {
    super(...arguments);

    this.onHelpClicked = this.onHelpClicked.bind(this);
    this.onHelpLoaderChange = this.onHelpLoaderChange.bind(this);

    this.state = {
      helpMinimized: true,
      shouldRenderHelp: false
    };
  }

  /**
   * Function to respond to help affordance clicked and set state accordingly
   * @param {React.MouseEvent} event The event object from the click
   */
  onHelpClicked(event) {
    const { features: { inappHelp } } = this.props;

    if (inappHelp) {
      event.preventDefault();
      this.setState({
        helpMinimized: false,
        shouldRenderHelp: true
      });
    }
  }

  /**
   * Function to respond to help onChange event and set state accordingly
   * @param {Object} state The incoming help state object after the onChange event
   * @param {boolean} state.minimized Whether the help container should be minimized
   */
  onHelpLoaderChange({ minimized }) {
    this.setState({ helpMinimized: minimized });
  }

  /**
   *
   * @returns {React.element} notifications component
   */
  renderNotifications() {
    const props = this.props;
    const { privateLabelId, urls } = props;
    const notificationMessages = Object.keys(golf.notifications).reduce((memo, key) => {
      memo[key] = <FormattedMessage id={ golf.notifications[key] } />;
      return memo;
    }, {});

    return (
      <Notifications
        manifest={ props.manifest }
        sso={ urls.sso }
        market={ props.market }
        shopperId={ props.shopperId }
        privateLabelId={ privateLabelId }
        messages={ notificationMessages }
        tooltip={ props.messages[golf.notifications.headerText] }
        showCount={ false }
        showOpenCaret={ true }
        { ...urls.notifications } />
    );
  }

  /**
   * Assesses help variant treatment and assigns appropriate UI.
   * Reseller and mobile are rendered serverside.
   * In all other cases there is no serverside help link
   * so that it renders blank before the treatment is
   * chosen on client side
   * @returns {React.Element} The li for the help link
   */
  renderHelp() {
    const props = this.props;
    if (props.preset === 'internal-header') {
      return null;
    }

    const isApiReseller = props.privateLabelType === 3;
    const isReseller = props.privateLabelId !== 1;
    let help;
    let label = <Icon name='help-header' />;
    let linkTitle = props.messages[golf.inApp];
    // the following code block is necessary so that reseller and mobile still
    // have a server-side render despite the split test
    if (!isApiReseller || (props.resellerUrls && props.resellerUrls.help)) {
      help = <li key='help' className={ this.namespace(
        this.classNames('nav-item', {
          'non-reseller': !isReseller
        })) }>
        { props.helpLoader
          ? (
            this.renderHelpLabel({
              label,
              tooltip: linkTitle,
              url: props.urls.help.href
            })
          )
          : (
            <InappHelp
              enabled={ props.features.inappHelp }
              url={ props.urls.help.href }
              ref={ c => { this.inappHelp = c; } }
              label={ label }
              tooltip={ linkTitle }
            />
          )
        }
      </li>;
    }

    if (this.isBrowser && !props.isMobile && !props.isTablet && !isReseller) {
      const { helpVariant } = props;
      const helpStrings = golf.help;
      const enUS = props.market.toLowerCase() === 'en-us';
      let id = helpStrings.help;
      if (helpVariant === 'A' || helpVariant === 'B' || enUS) {
        label = <React.Fragment>
          <FormattedMessage id={ id }/>&nbsp;
          <FormattedMessage id={ helpStrings.guides }>
            { (msg) => <span className={ this.namespace('by-guides') }>{ msg }</span> }
          </FormattedMessage>
        </React.Fragment>;
        linkTitle = props.messages[id];
      }
      if (helpVariant === 'C') {
        id = helpStrings.helpCenter;
        label = <FormattedMessage id={ id }/>;
        linkTitle = props.messages[id];
      } else if (helpVariant === 'D') {
        id = helpStrings.getHelp;
        label = <FormattedMessage id={ id }/>;
        linkTitle = props.messages[id];
      }
      help = <li key='help' className={ this.namespace(
        this.classNames('nav-item', {
          'help-text': enUS || ['A', 'B', 'C', 'D'].indexOf(helpVariant) !== -1,
          'faded-guides': helpVariant === 'B'
        })) }>
        { props.helpLoader
          ? (
            this.renderHelpLabel({
              label,
              tooltip: linkTitle,
              url: props.urls.help.href
            })
          )
          : (
            <InappHelp
              enabled={ props.features.inappHelp }
              url={ props.urls.help.href }
              ref={ c => { this.inappHelp = c; } }
              label={ label }
              tooltip={ linkTitle }
            />
          )
        }
      </li>;
    }
    return help;
  }

  /**
   * Render help label
   * @returns {React.ReactElement} The anchor encompassing the help label (if not icon)
   */
  renderHelpLabel({ label, tooltip, url }) {
    const labelIsIcon = label.type === Icon;

    return (
      <a
        href={ url }
        data-tcc-ignore
        onClick={ this.onHelpClicked }
        className={ this.namespace('nav-link', 'nav-link-help') }
        title={ labelIsIcon ? tooltip : void 0 }
      >
        { labelIsIcon
          ? (
            <span
              className={ this.namespace('uxicon', 'uxicon-help-header') }
              aria-hidden
            />
          )
          : label
        }
      </a>
    );
  }

  /**
   * Render help, cart, notifications, and account tray. ORDER MATTERS.
   * @returns {[React.element]} Array of components for each global element
   */
  renderGlobalElements() {
    const props = this.props;
    const loggedIn = props.loggedIn;
    const isInternal = props.preset === 'internal-header';
    const isPass = props.preset === 'pass-header';
    const elements = [];
    const isApiReseller = props.privateLabelType === 3;

    elements.push(this.renderHelp());

    if (!isInternal && !isPass && !isApiReseller) {
      elements.push(<li key='cart' className={ this.namespace(props.className, props.hidden, 'nav-item') }>
        <Cart
          items={ props.items }
          market={ props.market }
          messages={ props.messages }
          showEmpty={ true }
          checkout={ props.urls.checkout.href }
          className={ this.namespace('nav-link', 'nav-link-cart') } />
      </li>);
    }

    if (loggedIn && !isPass && !isInternal) {
      elements.push(<li key='notifications' className={ this.namespace('nav-item') }>
        { this.renderNotifications() }
      </li>);
    }

    if (!isPass) {
      elements.push(<li key='account-tray' className={ this.namespace('nav-item') }>
        <User { ...props } />
      </li>);
    }

    return elements;
  }

  /**
   * Renders left nav elements: logo, home button, app name.
   * @returns {React.element} The div encompassing left nav elements
   */
  renderLeftNav() {
    const props = this.props;
    const { privateLabelId, isMobile, isTablet, orderId, appName, app, messages, version, features } = props;
    const isNotDesktop = isMobile || isTablet;
    const logoClasses = this.namespace(this.classNames('logo-text', {
      'logo-gd': privateLabelId === 1
    }));
    const name = (['riviera', 'mya', 'go', 'account'].indexOf(app) >= 0) ?
      messages['PC:AppHeader:Header:Home'] : appName;

    // Needed for some GD Only css tweaks on mobile and phablet.
    const outsideLogoClasses = this.namespace(this.classNames('logo', {
      'brand-logo': privateLabelId === 1,
      'go-logo-anchor': features.go
    }));
    return (<div className={ this.namespace('nav-left-wrap') }>
      { orderId && <OrderConfirmation orderId={ orderId } /> }
      {
        isNotDesktop && <NavigationSet renderMobile={ isNotDesktop } navigationPosition='top' { ...props } />
      }
      <Logo
        className={ outsideLogoClasses }
        country={ props.countryName }
        href={ props.urls.home.href }
        market={ props.market }
        messages={ props.messages }
        logos={ props.logos }
        privateLabelId={ privateLabelId }
        privateLabelName={ props.privateLabelName }
        showCountryNameOnLogo={ false }
        spanClass={ logoClasses }
        urlArgs={ props.urlArgs }
        isMobile={ isMobile }
        go={ features.go }
        data-eid='uxp.hyd.app_header.gd_logo.link.click' />
      <div className={ this.namespace('nav', 'nav-left') }>
        <AppName { ...props } name={ name } />
      </div>
      {
        version &&
        <div className={ this.namespace('version', 'hidden-sm-down') }>
          <NamespacedVersion value={ props.version } />
        </div>
      }
    </div>);
  }

  render() {
    const { helpMinimized, shouldRenderHelp } = this.state;
    const { env, market, messages, isMobile, isTablet, navigationTop, shopperId, urls } = this.props;
    // safari only stuff :(
    let isThisSafari = false;
    if (typeof navigator !== 'undefined') {
      isThisSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    }

    return (
      <IntlProvider locale={ market } messages={ messages }>
        <nav className={ this.namespace(this.classNames('nav-top', 'clearfix', { 'safari-only': isThisSafari })) }>
          <div className={ this.namespace('container') }>
            <div className={ this.namespace('d-flex', 'flex-row') }>
              { this.renderLeftNav() }
              <ul className={ this.namespace('nav', 'nav-right') }>
                { !isMobile && !isTablet && navigationTop &&
                  <li className={ this.namespace('nav-item') }>
                    <div>
                      <a className={ this.namespace('custom-link') }
                        href={ navigationTop.href }
                        id={ navigationTop.id }
                        target={ navigationTop.target }
                        onClick={ navigationTop.onClick }
                        data-eid={ navigationTop.eid }>
                        { navigationTop.caption }
                      </a>
                    </div>
                  </li>
                }
                { !isMobile && !isTablet && navigationTop &&
                  <li className={ this.namespace('custom-links-divider') } />
                }
                { this.renderGlobalElements() }
              </ul>
            </div>
          </div>

          { shouldRenderHelp && (
            <HelpLoader
              env={ env }
              market={ market }
              product={ urls && urls.help && urls.help.href ? urls.help.href.split('/').pop() : null }
              shopperId={ shopperId }
              affordance={ false }
              zIndex={ 2000 }
              forceMinimized={ helpMinimized }
              onChange={ this.onHelpLoaderChange }
            />
          ) }

        </nav>
      </IntlProvider>
    );
  }
}

NavigationTop.update = function update(nav, done = () => {}) {
  if (Array.isArray(nav) && nav.length === 1) {
    return this.setState({
      navigationTop: nav[0]
    }, done);
  }
};

NavigationTop.updateHelpUrl = function updateHelpUrl(helpUrl, done = () => {}) {
  return this.setState({
    helpUrl
  }, done);
};

/**
 * Default properties for the top half of the navigation.
 *
 * @type {Object}
 * @api public
 */
NavigationTop.defaultProps = {
  app: 'domainmanager',
  features: {},
  preset: 'application-header',
  loggedIn: false,
  manifest: 'applicationheader',
  market: 'en-US',
  privateLabelId: 1,
  privateLabelName: 'GoDaddy.com',
  proshopper: false,
  urlArgs: {},
  sso: {
    app: 'www',
    path: ''
  },
  urls: {
    notifications: {
      api: { href: 'https://mya.dev-godaddy.com/webapi/notifications' },
      cache: { href: '//img1.wsimg-com.ide/mya/notifications/cache.html' },
      endpoint: { href: 'https://notifications.api.cloud.dev-godaddy.com/v1/notifications' }
    },
    help: { href: 'https://www.godaddy.com/help' },
    home: { href: 'https://www.godaddy.com' },
    contactUs: { href: 'https://www.godaddy.com/contact-us.aspx' },
    checkout: { href: 'https://cart.godaddy.com' },
    renewals: { href: 'https://account.godaddy.com/billing?filter=expires&subFilter=90' },
    account: { href: 'https://account.godaddy.com' },
    products: { href: 'https://account.godaddy.com/products' },
    pro: { href: 'https://pro.godaddy.com/launch/managewp?landingScreen=pro-home' },
    proHomeApp: { href: 'https://pro.godaddy.com/launch/managewp?landingScreen=pro-home' }
  }
};

/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */
NavigationTop.propTypes = {
  ...NamespaceShape,
  account: PropTypes.string,
  app: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  features: PropTypes.object,
  help: PropTypes.string,
  preset: PropTypes.string,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  items: PropTypes.number,
  loggedIn: PropTypes.bool,
  manifest: PropTypes.string,
  market: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  name: PropTypes.string,
  navigationTop: PropTypes.object,
  orderId: PropTypes.string,
  privateLabelId: PropTypes.number,
  privateLabelName: PropTypes.string,
  products: PropTypes.string,
  proshopper: PropTypes.bool,
  shopperId: PropTypes.string,
  showWaffleMenu: PropTypes.bool,
  renewals: PropTypes.string,
  sso: PropTypes.object,
  urlArgs: PropTypes.object,
  urls: PropTypes.object.isRequired,
  version: PropTypes.string,
  vip: PropTypes.object
};

export {
  NamespacedVersion as Version
};

export default withNamespace(NavigationTop);
