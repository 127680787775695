import React from 'react';
import PropTypes from 'prop-types';
import { golf } from './enums';
import { NamespaceConsumer, NamespaceShape, withNamespace } from '@ux/namespace-component';
import { FormattedMessage } from 'react-intl';
import PopOver from '@ux/popover';
import Link from '@ux/link';
import Icon from '@ux/icon';

/**
 * Render A waffle menu to show a long list of products
 *
 * @param {Object} options Properties to spread on the item
 * @param {Function} [namespace=no-op] Function to namespace `className`
 * @returns {React.Element} A Popover Menu that uses a waffle control.
 * @api private
 */
class WaffleMenu extends NamespaceConsumer {

  /**
   * Generate a link
   * @param  {Object} opts enumeration of link content
   * @param  {String} opts.message title of link
   * @param  {String} opts.id golf id
   * @param  {String} opts.href link to product
   * @param  {String} opts.eid eid to track click
   * @param  {React.Element} opts.prefix Icon to place in front of the link
   * @returns {React.element} link to product
   */
  link({ message, id, href, eid, prefix, className }) {
    return (
      <Link
        title={ message }
        href={ href }
        data-eid={ eid }
        className={ className }
        key={ `waffle-menu-${id}` }>
        { prefix } <FormattedMessage id={ id } />
      </Link>
    );
  }

  links() {
    const { urls, messages, isInGoCohort } = this.props;
    return {
      home: this.link({
        eid: 'uxp.hyd.waffle_menu.home.link.click',
        href: isInGoCohort ? urls.accountHome.href : urls.products.href,
        id: golf.home,
        message: messages[golf.home],
        className: this.namespace('waffle-home-link'),
        prefix: <Icon name='home' />
      }),
      manage: this.link({
        eid: 'uxp.hyd.waffle_menu.manage_my_products.link.click',
        href: urls.products.href,
        id: golf['manage-my-products'],
        className: this.namespace('waffle-link'),
        message: messages[golf['manage-my-products']]
      }),
      products: [{
        eid: 'uxp.hyd.waffle_menu.product_domains.link.click',
        href: urls.manageDomains.href,
        id: golf.domains.replace('_', '-'),
        className: this.namespace('waffle-link'),
        message: messages[golf.domains]
      }, {
        eid: 'uxp.hyd.waffle_menu.product_websites.link.click',
        href: urls.manageWebsites.href,
        id: golf.websites.replace('_', '-'),
        className: this.namespace('waffle-link'),
        message: messages[golf.websites]
      }, {
        eid: 'uxp.hyd.waffle_menu.product_hosting_wordpress.link.click',
        href: urls.manageHosting.href,
        id: golf['hosting-wordpress'].replace('_', '-'),
        className: this.namespace('waffle-link'),
        message: messages[golf['hosting-wordpress']]
      }, {
        eid: 'uxp.hyd.waffle_menu.product_email_office.link.click',
        href: urls.bigthree.email.href,
        id: golf['email-office'].replace('_', '-'),
        className: this.namespace('waffle-link'),
        message: messages[golf['email-office']]
      }, {
        eid: 'uxp.hyd.waffle_menu.product_ssl.link.click',
        href: urls.bigthree.ssl.href,
        id: golf.ssl.replace('_', '-'),
        className: this.namespace('waffle-link'),
        message: messages[golf.ssl]
      }].map(this.link)
    };
  }

  render() {
    const { showWaffleMenu, control } = this.props;

    if (!showWaffleMenu) {
      return null;
    }

    const links = this.links();

    return (
      <PopOver
        control={ control }
        eid='uxp.hyd.app_header.waffle_menu.click'
        className={ this.namespace('waffle-menu') }
      >
        { links.home }
        <div className={ this.namespace('tray-menu-divider') }></div>

        <div className={ this.namespace('quick-links-heading', 'tray-heading') }>
          <FormattedMessage id={ 'PC:AppHeader:Header:QuickLinks' } />
        </div>

        <div className={ this.namespace('quick-links') }>
          { links.products }
        </div>

        <div className={ this.namespace('tray-menu-divider') }></div>

        { links.manage }
      </PopOver>
    );
  }
}

WaffleMenu.propTypes = {
  ...NamespaceShape,
  control: PropTypes.element,
  showWaffleMenu: PropTypes.bool,
  urls: PropTypes.object,
  messages: PropTypes.object,
  isInGoCohort: PropTypes.bool
};

export default withNamespace(WaffleMenu);
