import React from 'react';
import PropTypes from 'prop-types';
import { golf, eids } from './enums';
import { url } from '@ux/header-util';
import { NamespaceConsumer, NamespaceShape, withNamespace } from '@ux/namespace-component';
import { TrayMenu } from '@ux/tray-menu';
import { IntlProvider, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { breakpoints } from './enums';

/**
 * Render User details when logged in.
 *
 * @Constructor
 * @class UserDetails
 * @api public
 */
class UserDetails extends NamespaceConsumer {

  renderRewardPoints() {
    const props = this.props;

    if (typeof props.rewardPoints !== 'number') {
      return null;
    }

    return (
      <div id={ this.namespace('user-details-reward-points') } className={ this.namespace('reward-points') }>
        <FormattedMessage id={ golf.proRewardPoints } />: { props.rewardPoints }
      </div>
    );
  }

  render() {
    const props = this.props;

    if (!props.loggedIn) {
      return null;
    }

    let details = null;

    if (props.preset === 'internal-header') {
      details = (
        <div className={ this.namespace('account-name') }>
          <FormattedMessage id={ golf.username } />: { props.username }
        </div>
      );
    } else if (props.privateLabelType !== 3) {
      details = (
        <div>
          <div className={ this.namespace('shopper-id') }>
            <FormattedMessage id={ golf.shopperid } />: { props.shopperId }
          </div>
          <div className={ this.namespace('shopper-pin') }>
            <FormattedMessage id={ golf.pinLabel } /> <a { ...props.urls.viewPin } target='_blank'>
              <FormattedMessage id={ golf.viewPin } />
            </a>
          </div>
        </div>
      );
    }

    return (
      <IntlProvider locale={ props.market } messages={ props.messages }>
        <div>
          { details }
          { props.proshopper && this.renderRewardPoints() }
          <NamespacedVIP details={ props.vip } market={ props.market } messages={ props.messages } />
        </div>
      </IntlProvider>
    );
  }
}

/**
 * Property definitions.
 *
 * @type {Object}
 * @api public
 */
UserDetails.propTypes = {
  ...NamespaceShape,
  urls: PropTypes.object.isRequired,
  name: PropTypes.string,
  shopperId: PropTypes.string,
  market: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  vip: PropTypes.object,
  username: PropTypes.string,
  proshopper: PropTypes.bool,
  proRewardPoints: PropTypes.number
};

/**
 * Render VIP information if required.
 *
 * @Constructor
 * @class VIP
 * @api public
 */
class VIP extends NamespaceConsumer {
  render() {
    const props = this.props;
    const vip = props.details;

    if (typeof vip !== 'object') {
      return null;
    }

    return (
      <IntlProvider locale={ props.market } messages={ props.messages }>
        <div className={ this.namespace('shopper-vip') }>
          <div className={ this.namespace('shopper-vip-label') }>
            <FormattedMessage id={ golf.vip } />:
          </div>

          <div className={ this.namespace('shopper-vip-details') }>
            <div>
              { vip.repname }
            </div>

            <div>
              { vip.phone }
            </div>

            <a href={ `mailto:${ vip.email }` }>
              { vip.email }
            </a>
          </div>
        </div>
      </IntlProvider>
    );
  }
}

/**
 * Property definitions.
 *
 * @type {Object}
 * @api private
 */
VIP.propTypes = {
  ...NamespaceShape,
  details: PropTypes.object,
  market: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired
};

/**
 * Render user dropdown menu.
 *
 * @Constructor
 * @class User
 * @api public
 */
class User extends NamespaceConsumer {
  constructor() {
    super(...arguments);
    this.state = this.state || {};
    this.state = {
      ...this.state,
      open: false
    };
  }

  /**
  * Add eventListeners for breakpoints.
  *
  * @api private
  */
  componentDidMount() {
    this.breakpoint(...breakpoints);
  }

  /**
  * Remove the eventListeners of breakpoints.
  *
  * @api private
  */
  componentWillUnmount() {
    this.breakup();
  }

  /**
  * Show link for pro dashboard only added if a user is pro
  *
  * @returns {React.Component} Component
  * @api private
  */
  renderPro() {
    const props = this.props;

    if (props.loggedIn && props.proshopper) {
      return (
        <a className={ this.namespace('customer-link') }  { ...props.urls.proHomeApp } data-eid={ eids.pro }>
          <FormattedMessage tagName='span' id={ golf.pro } />
        </a>
      );
    }
    return null;
  }

  /**
   * Show title on the dropdown menu, or show the user's name
   * if user has a name
   *
   * @returns {React.Component} Component
   */
  renderDropdownHeader() {
    const props = this.props;

    if (props.loggedIn && props.name) {
      return props.name;
    }
    return (
      <FormattedMessage id={ props.preset === 'internal-header' ? golf.internalModeTitle : golf.title } />
    );
  }

  /**
  * Show link for products, account, and renewals except
  * for internal header and pl
  *
  * @returns {React.Component} Component
  * @api private
  */
  renderLinks() {
    const props = this.props;
    if (props.preset === 'internal-header') return null;

    const links = [
      props.privateLabelId === 1 && props.isInGoCohort &&
      <a className={ this.namespace('customer-link') }
        key='accountHome'
        href={ url.updateQuery(props.urls.accountHome.href, props.urlArgs) }
        data-eid={ eids.accountHome }>
        <FormattedMessage id={ golf.accountHome } />
      </a>,

      (props.privateLabelType !== 3 || (props.resellerUrls && props.resellerUrls.products)) &&
      <a className={ this.namespace('customer-link') }
        key='products'
        href={ url.updateQuery(props.urls.products.href, props.urlArgs) }
        data-eid={ eids.products }>
        <FormattedMessage id={ golf.products } />
      </a>,

      (props.privateLabelType !== 3 || (props.resellerUrls && props.resellerUrls.account)) &&
      <a className={ this.namespace('customer-link') }
        key='account' { ...props.urls.account }
        data-eid={ eids.accountSettings }>
        <FormattedMessage id={ golf.accountSettings } />
      </a>,

      props.privateLabelType !== 3 &&
      <a className={ this.namespace('customer-link') }
        key='renewals' { ...props.urls.renewals }
        data-eid={ eids.renewals }>
        <FormattedMessage id={ golf.renewals } />
      </a>
    ].filter(Boolean);

    if (props.privateLabelId === 1) {
      links.push(...[
        <div className={ this.namespace('tray-menu-divider') } key='resourcesDivider' />,

        <a className={ this.namespace('customer-link') }
          key='shopGD' { ...props.urls.home }
          data-eid={ eids.shopGD }>
          <FormattedMessage id={ golf.shopGD } />
        </a>
      ]);
    }

    if (props.features.accountTrayContactLink) {
      links.push(<a className={ this.namespace('customer-link') }
        key='phoneNumbersHours' { ...props.urls.contactUs }
        data-eid={ eids.phoneNumbersHours }>
        <FormattedHTMLMessage id={ golf.phoneNumbersHours } />
      </a>);
    }

    return links;
  }

  render() {
    const props = this.props;
    const status = props.loggedIn ? 'logout' : 'login';
    const privateLabelId = props.privateLabelId;
    const isDesktop = !~['mobile', 'phablet', 'tablet'].indexOf(this.state.breakpoint);

    let trayName = '';

    if (isDesktop && privateLabelId === 1) {
      if (props.loggedIn) {
        // props.displayName is the short name (e.g. "Jane").
        trayName = props.displayName;
      } else {
        trayName = <FormattedMessage id={ golf.signin } />;
      }
    }

    const traySpanClassName = (privateLabelId !== 1 || !isDesktop) ? this.namespace('app-icon', 'app-icon-user') : this.namespace('customer-name-span');

    return (
      <IntlProvider locale={ props.market } messages={ props.messages }>
        <div>
          <FormattedMessage id={ props.preset === 'internal-header' ? golf.internalModeTitle : golf.title }>
            {(userTray) =>
              <TrayMenu
                ariaLabel={ userTray }
                trayAlignment='right'
                name={ trayName }
                className={ this.namespace('customer-menu') }
                showOpenCaret={ true }
                traySpanClass={ traySpanClassName }
                type='base'
                fullwidth={ false }
                onToggle={ () => {
                  this.setState({
                    open: !this.state.open
                  });
                } }
              >
                <div className={ this.namespace('customer-info-wrap') }>


                  <div className={ this.namespace('customer-name') }>
                    { this.renderDropdownHeader() }
                  </div>

                  <div className={ this.namespace('customer-details') }>
                    <NamespacedUserDetails { ...props } />
                  </div>
                </div>

                <div key='accountText' className={ this.namespace('tray-heading') }>
                  <FormattedMessage id={ golf.account } />
                </div>

                { this.renderPro() }

                { this.renderLinks() }

                <a className={ this.namespace('customer-link', 'login') }
                  href={ url.updateQuery(props.urls.sso[status].href, props.sso) }
                  data-eid={ eids[status] }>
                  <FormattedMessage id={ golf[status] } />
                </a>

              </TrayMenu>
            }
          </FormattedMessage>

          { (props.isMobile || props.isTablet) && this.state.open &&
            <div className={ this.namespace('menu-bg') } /> }
        </div>
      </IntlProvider>
    );
  }
}

/**
 * Property definitions.
 *
 * @type {Object}
 * @api public
 */
User.propTypes = {
  ...NamespaceShape,
  urls: PropTypes.object.isRequired,
  urlArgs: PropTypes.object,
  privateLabelId: PropTypes.number,
  displayName: PropTypes.string,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  loggedIn: PropTypes.bool,
  proshopper: PropTypes.bool,
  sso: PropTypes.object,
  version: PropTypes.string,
  market: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  name: PropTypes.string
};

const NamespacedUserDetails = withNamespace(UserDetails);
const NamespacedVIP = withNamespace(VIP);
const NamespacedUser = withNamespace(User);
export {
  NamespacedUserDetails as UserDetails,
  NamespacedVIP as VIP,
  NamespacedUser as User
};
