import { NamespaceConsumer, NamespaceShape, withNamespace } from '@ux/namespace-component';
import NavigationBottom from './nav-bottom';
import NavigationTop from './nav-top';
import React from 'react';
import { breakpoints } from './enums';
import PropTypes from 'prop-types';

class Header extends NamespaceConsumer {
  constructor() {
    super(...arguments);

    this.state = {
      ...this.state,
      mobileMenuOpen: false
    };
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  /**
   * Add eventListeners for breakpoints.
   *
   * @api private
   */
  componentDidMount() {
    this.breakpoint(...breakpoints);
  }

  /**
   * Remove the eventListeners of breakpoints.
   *
   * @api private
   */
  componentWillUnmount() {
    this.breakup();
  }

  /**
   * Function to set state indicating if mobile menu is open or not
   * @param  {boolean} open The open state of the mobile menu
   */
  toggleMobileMenu(open) {
    this.setState({
      mobileMenuOpen: open
    });
  }

  render() {
    const props = this.props;
    const splitClassName = props.split || '';
    const isDesktop = !~['mobile', 'phablet', 'tablet'].indexOf(this.state.breakpoint);

    let showWaffleMenu = false;
    if (props.preset === 'application-header' && props.privateLabelId === 1) {
      if (['help', 'community', 'system-status'].indexOf(props.app) === -1) {
        // the help app is the only app with a logged-out state for app-header
        // if the app is not help, we dont have to check for loggedin and
        // can render server side to avoid flashing
        showWaffleMenu = true;
      } else if (props.loggedIn) {
        showWaffleMenu = true;
      }
    }

    const classNames = this.namespace(this.classNames(
      'manifest',
      { 'mobile-nav-open': this.state.mobileMenuOpen,
        'pl': props.privateLabelId !== 1,
        splitClassName
      }
    ));

    return (
      <header className={ classNames }>
        <NavigationTop { ...props }
          showWaffleMenu={ showWaffleMenu }
          isMobile={ this.isMobile }
          isTablet={ this.state.breakpoint === 'tablet' } />
        { isDesktop && <NavigationBottom { ...props } onClick={ this.toggleMobileMenu } /> }
      </header>
    );
  }
}

Header.propTypes = {
  ...NamespaceShape,
  accountHomeButton: PropTypes.bool,
  features: PropTypes.object,
  mobileSEO: PropTypes.bool,
  preset: PropTypes.string,
  showWaffleMenu: PropTypes.bool
};

export default withNamespace(Header);
